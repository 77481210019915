<template>
  <div
    ref="controls"
    :class="[ 'control-list', { 'control-list--is-sticky': isControlsSticky } ]"
  >
    <div class="control-list__inner">
      <div class="control-list__title">
        Контролы
      </div>
      <div class="control-list__body">
        <template v-if="isControlSorting">
          <esmp-collapser
            v-if="standardControls.length"
            class="control-list__group"
            :is-expanded.sync="standard.isExpanded"
            :title="standard.name"
          >
            <draggable
              :list="standardControls"
              :group="{ name: 'controls', pull: 'clone', put: false }"
              class="control-list__group-body"
              :clone="clone"
              :sort="false"
            >
              <div
                class="control-list__item"
                v-for="control in standardControls"
                :key="control.id"
              >
                <div class="control-list__item-icon">
                  <esmp-icon :name="getIconName(control.classType)" />
                </div>
                <div class="control-list__item-name">
                  {{ getViewName(control.classType) }}
                </div>
              </div>
            </draggable>
          </esmp-collapser>
          <esmp-collapser
            v-if="specialControls.length"
            class="control-list__group"
            :is-expanded.sync="special.isExpanded"
            :title="special.name"
          >
            <draggable
              :list="specialControls"
              :group="{ name: 'controls', pull: 'clone', put: false }"
              class="control-list__group-body"
              :clone="clone"
              :sort="false"
            >
              <div
                class="control-list__item"
                v-for="control in specialControls"
                :key="control.id"
              >
                <div class="control-list__item-icon">
                  <esmp-icon :name="getIconName(control.classType)" />
                </div>
                <div class="control-list__item-name">
                  {{ getViewName(control.classType) }}
                </div>
              </div>
            </draggable>
          </esmp-collapser>
          <esmp-collapser
            v-if="individualControls.length"
            class="control-list__group"
            :is-expanded.sync="individual.isExpanded"
            :title="individual.name"
          >
            <draggable
              :list="individualControls"
              :group="{ name: 'controls', pull: 'clone', put: false }"
              class="control-list__group-body"
              :clone="clone"
              :sort="false"
            >
              <div
                class="control-list__item"
                v-for="control in individualControls"
                :key="control.id"
              >
                <div class="control-list__item-icon">
                  <esmp-icon :name="getIconName(control.classType)" />
                </div>
                <div class="control-list__item-name">
                  {{ getViewName(control.classType) }}
                </div>
              </div>
            </draggable>
          </esmp-collapser>
        </template>
        <div v-else class="control-list__group">
          <draggable
            :list="controls"
            :group="{ name: 'controls', pull: 'clone', put: false }"
            class="control-list__group-body"
            :clone="clone"
            :sort="false"
          >
            <div
              class="control-list__item"
              v-for="control in controls"
              :key="control.id"
            >
              <div class="control-list__item-icon">
                <esmp-icon :name="getIconName(control.classType)" />
              </div>
              <div class="control-list__item-name">
                {{ getViewName(control.classType) }}
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions } from 'vuex';
import Hub from '@/plugins/event-hub';
import controlsMap from '@/components/service-form/controls.map';

export default {
  name: 'ControlList',
  components: { draggable },
  props: {
    controls: {
      type: Array,
      required: true,
    },
    isControlSorting: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      standard: {
        isExpanded: true,
        name: 'Стандартные',
      },
      special: {
        isExpanded: false,
        name: 'Специальные',
      },
      individual: {
        isExpanded: false,
        name: 'Индивидуальные',
      },
      controlsTopOffset: null,
    };
  },
  computed: {
    standardControls() {
      return this.controls.filter((el) => el.groupType === 'standard');
    },
    specialControls() {
      return this.controls.filter((el) => el.groupType === 'special');
    },
    individualControls() {
      return this.controls.filter((el) => el.groupType === 'individual');
    },
    isControlsSticky() {
      return this.controlsTopOffset <= 0;
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    getIconName(classType) {
      const controller = controlsMap[classType];
      return controller ? controller.icon : 'control__nameless';
    },
    getViewName(classType) {
      const controller = controlsMap[classType];
      return controller ? controller.viewName : 'Неизвестный тип';
    },
    clone(el) {
      const element = cloneDeep(el);
      const id = Number(window.HM_CURRENT_UNIQID());
      let settings;
      if (element.settings) {
        settings = element.settings.map((s) => {
          if (s.techName === 'searchType') {
            s.options.forEach((option) => {
              const child = option.childs
                .find((c) => c.techName === 'customEmployeeSearchListEnabled').childs
                .find((c) => c.techName === 'customEmployeeSearchList');
              if (!child.values) child.values = [];
            });
          }
          return s;
        });
      }
      return {
        childs: element.classType === 'group' || element.classType === 'table' ? [] : undefined,
        techId: id,
        groupId: element.classType === 'group' ? id : undefined, // обязательное почему-то для бека
        ...omit(element, 'id', 'name', 'groupType'),
        settings,
      };
    },
    getControlsTopOffset() {
      this.controlsTopOffset = this.$refs.controls.getBoundingClientRect().top;
    },
  },
  created() {
    Hub.$on('scroll', () => {
      this.getControlsTopOffset();
    });
    Hub.$on('resize', () => {
      const offsetTop = this.$refs.controls.getBoundingClientRect().top;
      if (offsetTop > this.controlsTopOffset) {
        this.controlsTopOffset = offsetTop;
      }
    });
  },
  mounted() {
    this.getControlsTopOffset();
  },
  beforeDestroy() {
    Hub.$off('scroll');
    Hub.$off('resize');
  },
};
</script>

<style lang="scss" scoped>
$controls-height: calc(100vh - 80px);

.control-list {
  $parent: &;
  position: relative;
  background-color: $color-white;
  border-radius: $base-border-radius * 2;
  height: $controls-height;

  &--is-sticky &__inner {
    position: fixed;
    top: 0;
  }
  &__inner {
    background-color: $color-white;
    border-radius: $base-border-radius * 2;
    display: flex;
    flex-direction: column;
    max-height: $controls-height;
    width: $controls-width;
  }
  &__title {
    width: 100%;
    padding: 12px 20px 10px;
    border-bottom: 1px solid $color-black-op-10;
    border-radius: $base-border-radius * 2 $base-border-radius * 2 0 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    flex-shrink: 0;
  }
  &__body {
    padding: 12px 20px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__group {
    padding: 0;
    border: none;
    background-color: $color-white;
    margin: 10px 0 0 0;
    &:first-child {
      margin: 0;
    }
    ::v-deep .esmp-collapser__body {
      padding-top: 12px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-grayscale-05;
      border-radius: $base-border-radius;
      flex-shrink: 0;
      margin-right: 12px;
      opacity: 0.8;
    }
    &-name {
      font-size: 14px;
    }
  }
}
</style>
